import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Autocomplete, MenuItem, Select, TextField, Radio, RadioGroup, FormControl,FormControlLabel, Snackbar, Alert } from "@mui/material";
import { createBooking, getLocations, getPilotAndType } from "../api";
import "../styles/BookingPage.css"; // Separate CSS file for styling



const BookingPage = () => {
  const locationHook = useLocation();
  const queryParams = new URLSearchParams(locationHook.search);
  const initialBookingType = queryParams.get("type") || "Out";
  const [bookingType, setBookingType] = useState(initialBookingType);
  
  const [registration, setRegistration] = useState("");
  const [aircraftType, setAircraftType] = useState("");
  const [pilot, setPilot] = useState("");
  const [pax, setPax] = useState(1);
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
  const [time, setTime] = useState(new Date().toISOString().split("T")[1].substring(0, 5));
  const [location, setLocation] = useState("");
  const [allLocations, setAllLocations] = useState([]);
  const [securityQuestion, setSecurityQuestion] = useState("");
  const [securityAnswer, setSecurityAnswer] = useState("");

  const navigate = useNavigate();
  const redirectTo = new URLSearchParams(locationHook.search).get("redirect");
  const securityQuestions = ["North", "South", "East", "West"];

    // Snackbar state
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  

  // Fetch locations on mount
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await getLocations();
        setAllLocations(response.locations || []);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };
    fetchLocations();
  }, []);

  // Format registration on blur
  const handleRegistrationBlur = () => {
    if (registration.trim() !== "") {
      let formatted = registration.toUpperCase().trim();
      if (/^G[0-9A-Z]{1,4}$/.test(formatted)) {
        formatted = `G-${formatted.slice(1)}`;
      }
      setRegistration(formatted);
      if (!pilot || !aircraftType) fetchPilotAndType(formatted);
    }
  };

  const fetchPilotAndType = async (formattedRegistration) => {
    try {
      const { aircraft_type, pilot: fetchedPilot } = await getPilotAndType(formattedRegistration);
      setAircraftType(aircraftType || aircraft_type || "");
      setPilot(pilot || (fetchedPilot || "").toUpperCase());
    } catch (error) {
      console.error("Error fetching pilot/type info:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    // Navigate instantly when Snackbar is closed
    navigate(redirectTo === "adminHomepage" ? "/admin" : "/");
  
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!securityAnswer.trim()) {
      alert("Please answer the security question.");
      return;
    }
    const bookingData = {
      registration,
      aircraft_type: aircraftType,
      pilot,
      pax,
      location,
      date,
      time,
      booking_type: bookingType,
      security_question: securityQuestion,
      security_answer: securityAnswer,
    };
    try {
      const response = await createBooking(bookingData);
      if (response.status === 201) {
        setSnackbarMessage("Booking Successful!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      // Delay navigation to allow Snackbar visibility
      setTimeout(() => {
        navigate(redirectTo === "adminHomepage" ? "/admin" : "/");
      }, 6000);  // Delay matches Snackbar autoHideDuration
      } else {
        alert("Booking failed: Did you answer the security question correctly?");
      }
    } catch (error) {
      alert(`Booking failed: ${error.response ? error.response.data : error.message}`);
    }
  };

  const handleCancel = () => {
    navigate(redirectTo === "adminHomepage" ? "/admin" : "/");
  };

  useEffect(() => {
    setSecurityQuestion(securityQuestions[Math.floor(Math.random() * securityQuestions.length)]);
  }, []);

  return (
    <div className="booking-container">


      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}         anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <h1 className="booking-title">Booking {bookingType} Page</h1>
      <form onSubmit={handleSubmit} className="booking-form">

        <div className="form-group" style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
  <TextField
    required
    type="text"
    label="Registration"
    value={registration}
    onChange={(e) => setRegistration(e.target.value.toUpperCase().slice(0, 20))}
    onBlur={handleRegistrationBlur}
    placeholder="Registration"
    style={{     width: '100%', // Ensures the input box fills its container
      textAlign: 'left', }}
  />
  <TextField
    type="text"
    value={aircraftType}
    onChange={(e) => setAircraftType(e.target.value)}
    placeholder="Aircraft Type"
    label="Aircraft Type"
    required
    style={{ flex: 1, textAlign: 'right' }}
  />
</div>

<div className="form-group" style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
  {/* Pilot Field */}
  <TextField
    type="text"
    label="Pilot"
    value={pilot}
    onChange={(e) => setPilot(e.target.value.toUpperCase())}
    placeholder="Pilot"
    required
    style={{ flex: 4, textAlign: 'left' }}
  />
  
  {/* POB Selector with Label */}
  <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap: '0.5rem', minWidth: '100px' }}>
  <label htmlFor="pob-selector" style={{ fontSize: '0.875rem', whiteSpace: 'nowrap' }}>
    POB
  </label>
  <Select
    id="pob-selector"
    value={pax}
    onChange={(e) => setPax(Number(e.target.value))}
    style={{
      flex: 1,
      minWidth: '80px',
      backgroundColor: 'white', // Ensures the dropdown always has a white background
    }}
    MenuProps={{
      PaperProps: {
        style: {
          backgroundColor: 'white', // Ensures dropdown items have a white background
        },
      },
    }}
  >
    {[...Array(10).keys()].map((n) => (
      <MenuItem key={n + 1} value={n + 1}>
        {n + 1}
      </MenuItem>
    ))}
  </Select>
</div>
</div>



<Autocomplete
  freeSolo
  options={allLocations}
  value={location}
  onInputChange={(e, newValue) => setLocation(newValue.toUpperCase())}
  renderInput={(params) => (
    <TextField
      {...params}
      label={bookingType === "In" ? "From" : "To"}
      required
      style={{
        flex: 1,
        backgroundColor: 'white', // Ensures the input field has a white background
        borderRadius: '4px', // Optional: For consistent rounded corners
      }}
      InputProps={{
        ...params.InputProps,
        style: {
          ...params.InputProps.style,
          backgroundColor: 'white', // Applies white background specifically to the input
        },
      }}
    />
  )}
/>




<div className="form-group date-time-group" style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 1 }}>
    <label htmlFor="date-field" style={{ marginBottom: '0.25rem', fontSize: '0.875rem' }}>Date</label>
    <TextField
      id="date-field"
      type="date"
      value={date}
      onChange={(e) => setDate(e.target.value)}
      required
      style={{ width: '100%' }}
    />
  </div>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 1 }}>
    <label htmlFor="time-field" style={{ marginBottom: '0.25rem', fontSize: '0.875rem' }}>Time</label>
    <TextField
      id="time-field"
      type="time"
      value={time}
      onChange={(e) => setTime(e.target.value)}
      required
      style={{ width: '100%' }}
    />
  </div>
</div>

<div className="form-group security-question" style={{ marginTop: '1rem' }}>
  <label htmlFor="security-answer" style={{ display: 'block', marginBottom: '0.5rem', fontSize: '0.875rem' }}>
    {`What is the runway number of Popham's ${securityQuestion} runway?`}
  </label>
  <TextField
    id="security-answer"
    label="Answer"
    value={securityAnswer}
    onChange={(e) => setSecurityAnswer(e.target.value)}
    required
    style={{ width: '100%' }}
  />
</div>



        <div className="form-buttons">
          <button type="button" className="secondary-btn" onClick={handleCancel}>
            Cancel
          </button>
          <button type="submit" className="primary-btn">
            Submit
          </button>
        </div>
      </form>
    </div>
    
  );
};

export default BookingPage;
