import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Grid2, Typography, Button, TextField } from '@mui/material';
import { getTodayBookings, fetchAirfield } from '../api';
import dayjs from 'dayjs';
//import Grid2 from '@mui/material/Unstable_Grid2'; // Ensure you're using the correct import


const HomePage = () => {
  const navigate = useNavigate();
  const [todayBookings, setTodayBookings] = useState([]);
  const [search, setSearch] = useState('');
  const [airfieldState, setAirfieldState] = useState({
      state: 'Active',
      cafe_state: 'Open',
      banner_message: '',
    });

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  useEffect(() => {
    const fetchAirfieldState = async () => {
      try {
        const response = await fetchAirfield(); // Dynamic API call
        if (response.success) {
          setAirfieldState({
            state: response.airfield.state, // Only use the state for now
          });
        } else {
          console.error("Failed to fetch airfield details: success=false");
        }
      } catch (error) {
        console.error("Error fetching airfield state:", error);
      }
    };
    fetchAirfieldState();
  }, []);
  
      
      

  useEffect(() => {
    const getBookings = async () => {
      try {
        const todayBookings = await getTodayBookings();

        // Sort by latest date and time
        const sortByLatest = (a, b) => new Date(b.date + ' ' + b.time) - new Date(a.date + ' ' + a.time);

        setTodayBookings(todayBookings.sort(sortByLatest));

      } catch (error) {
        console.error('Error fetching bookings:', error.response?.data || error.message);
      }
    };


    getBookings();
  }, []);


    const renderBookingCard = (booking) => (
<Card variant="outlined" sx={{ marginBottom: 1, padding: 1 }}>
  <CardContent>
    <Grid2 container spacing={2}>
      <Grid2 xs={6} sm={3} sx={{ minWidth: '10px' }}>
        <Typography variant="subtitle2" color="textSecondary">In/Out</Typography>
        <Typography>{booking.booking_type}</Typography>
      </Grid2>
      <Grid2 xs={6} sm={3} sx={{ minWidth: '80px' }}>
        <Typography variant="subtitle2" color="textSecondary">Date</Typography>
        <Typography>{dayjs(booking.date).format('DD-MMM-YYYY')}</Typography>
      </Grid2>
      <Grid2 xs={6} sm={3} sx={{ minWidth: '50px' }}>
        <Typography variant="subtitle2" color="textSecondary">Time</Typography>
        <Typography>{booking.time}</Typography>
      </Grid2>
      <Grid2 xs={6} sm={3} sx={{ minWidth: '100px' }}>
        <Typography variant="subtitle2" color="textSecondary">A/C Type</Typography>
        <Typography>{booking.aircraft_type}</Typography>
      </Grid2>
      <Grid2 xs={6} sm={4} sx={{ minWidth: '60px' }}>
        <Typography variant="subtitle2" color="textSecondary">Registration</Typography>
        <Typography>{booking.registration}</Typography>
      </Grid2>
      <Grid2 xs={6} sm={4} sx={{ minWidth: '140px' }}>
        <Typography variant="subtitle2" color="textSecondary">From / To</Typography>
        <Typography>{booking.location}</Typography>
      </Grid2>
      <Grid2 xs={6} sm={4} sx={{ minWidth: '140px' }}>
        <Typography variant="subtitle2" color="textSecondary">Pilot</Typography>
        <Typography>{booking.pilot}</Typography>
      </Grid2>
      <Grid2 xs={6} sm={2} sx={{ minWidth: '20px' }}>
        <Typography variant="subtitle2" color="textSecondary">POB</Typography>
        <Typography>{booking.pax}</Typography>
      </Grid2>
    </Grid2>
  </CardContent>
</Card>
    
    );

  return (
    <div className="home-page-container">
      <div className="logo-container">
        <img src="/popham-logo.png" alt="Popham Aircraft Booking Logo" className="logo" />
      </div>
      <h1 className="home-page-title">Welcome to Popham</h1>

      <div className="home-button-group">
  <div className="airfield-state">
    {airfieldState.state ? `Airfield Status: ${airfieldState.state}` : "Loading..."}
  </div>
  <Button variant="contained" color="primary" onClick={() => navigate('/booking?type=In')}>
  Book In
</Button>
<Button variant="contained" color="secondary" onClick={() => navigate('/booking?type=Out')} style={{ marginLeft: '1rem' }}>
  Book Out
</Button>
</div>


      {/* User Booking History */}
      <div className="home-table-container">
        <h3>Todays Movements</h3>
        <TextField
          id="search"
          label="Search Aircraft Movements"
          variant="outlined"
          fullWidth
          margin="normal"
          value={search}
          onChange={handleSearchChange}
        />
        {todayBookings
          .filter(
            (booking) =>
              booking.date.toLowerCase().includes(search) ||
              booking.registration.toLowerCase().includes(search) ||
              booking.pilot.toLowerCase().includes(search)
          )
          .map((booking, index) => (
            <React.Fragment key={index}>{renderBookingCard(booking)}</React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default HomePage;
