import axios from 'axios';

//const API_URL = "http://127.0.0.1:5000"; 
//const API_URL = "https://popout.giles53716.com/api"; 
const API_URL = "https://movements.popham-airfield.co.uk/api"; 
// const API_URL = "https://yujjc2e2qi.execute-api.eu-west-1.amazonaws.com/dev" // Update to match your backend URL

export const api = axios.create({
    baseURL: API_URL,
});


// Function to handle login
export const loginUser = async (username, password) => {
  try {
    const response = await axios.post(`${API_URL}/login`, { username, password });
    if (response.data.success) {
      localStorage.setItem('adminToken', response.data.token); // Save token
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetch the most common Pilot and Aircraft type for a registration
export const getPilotAndType = async (registration) => {
  try {
    const response = await api.get(`/registration_info?registration=${registration}`, {
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get a unique list of locations from the database
export const getLocations = async () => {
  try {
    const response = await api.get(`/locations`, {
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};




// Fetch bookings with filter type (today, month, year, all)
export const fetchBookingsByRange = async (filterType, token) => {
  try {
    const response = await api.get(`/bookings?filter=${filterType}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Update a booking
export const updateBooking = async (bookingId, updatedData, token) => {
  try {
    const response = await api.put(`/bookings/${bookingId}`, updatedData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Delete a booking
export const deleteBooking = async (bookingId, token) => {
  try {
    const response = await api.delete(`/bookings/${bookingId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Update a airfield information
export const updateAirfield = async (airfieldState, token) => {
  try {
    const response = await api.put(`/airfield`, airfieldState, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Update a get airfield data
export const fetchAirfield = async () => {
  try {
    const response = await api.get(`/airfield`);
    return response.data;
  } catch (error) {
    throw error;
  }
};



// Fetch bookings function
export const fetchBookings = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/bookings`);
      return response.data;  // Return the data from the response
    } catch (error) {
      throw error;  // Propagate the error
    }
  };

 
// Fetch user data function
export const fetchUserData = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/account`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;  // Return user data
  } catch (error) {
    throw error;  // Propagate error
  }
};

// Fetch all bookings with token
export const getAllBookings = async () => {
  try {
    const token = localStorage.getItem('adminToken');
    const response = await axios.get(`${API_URL}/all-bookings`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetch today's bookings function
export const getTodayBookings = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/bookingstoday`);
    return response.data; // Return today's bookings data
  } catch (error) {
    throw error; // Propagate error
  }
};


// Fetch user data
export const getUserData = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/account`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    throw error; // Propagate error
  }
};

// Submit a booking
export const createBooking = async (bookingData) => {
  try {
    const response = await axios.post(`${API_URL}/booking`, bookingData);
    return response;
  } catch (error) {
    throw error; // Propagate error
  }
};


// Update user account details
export const updateUserAccount = async (userData, token) => {
  try {
    const response = await axios.put(`${API_URL}/account`, userData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    throw error; // Propagate error
  }
};

// Function to get all accounts (for the admin)
export const getAllAccounts = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/accounts`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching accounts:', error);
    throw error;
  }
};

// Function to update a specific account (for the admin)
export const updateAccount = async (token, accountData) => {
  try {
    const response = await axios.put(`${API_URL}/accounts/${accountData.id}`, accountData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating account:', error);
    throw error;
  }
};







