import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Grid2,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import dayjs from 'dayjs';
import '../App.css';
import { fetchBookingsByRange, updateBooking, updateAirfield, fetchAirfield, deleteBooking } from '../api';

const AdminHomePage = ({ user, setUser, setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [allBookings, setAllBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [search, setSearch] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [airfieldDialogOpen, setAirfieldDialogOpen] = useState(false);
  const [airfieldState, setAirfieldState] = useState({
    state: 'Active',
    cafe_state: 'Open',
    banner_message: '',
  });

const [selectedBookingId, setSelectedBookingId] = useState(null);

const handleAirfieldDialogOpen = () => {
  setAirfieldDialogOpen(true);
}

const handleAirfieldDialogClose = () => {
  setAirfieldDialogOpen(false);
}



const handleDeleteClick = (id) => {
  setSelectedBookingId(id);
  setDeleteDialogOpen(true);
};

useEffect(() => {
  const token = localStorage.getItem('adminToken');
  if (!token) {
    navigate('/admin-login'); // Redirect to login if no token is found
  } else {
    // Optional: Validate token with backend
    setIsLoggedIn(true);
    fetchBookings('today'); // Fetch bookings for today's range
  }
}, [navigate, setIsLoggedIn]);

const confirmDelete = async () => {
  if (selectedBookingId) {
    try {
      await handleDelete(selectedBookingId); // Delete logic
      await fetchBookings('today'); // Refresh bookings after delete
    } catch (error) {
      console.error('Error refreshing bookings:', error);
    } finally {
      setDeleteDialogOpen(false);
      setSelectedBookingId(null);
    }
  }
};

const cancelDelete = () => {
  setDeleteDialogOpen(false);
  setSelectedBookingId(null);
};



    // Fetch airfield state on load
    useEffect(() => {
      const fetchAirfieldState = async () => {
        try {
          const fetchedState = await fetchAirfield(); // Dynamic API call    
          if (fetchedState.success) {
            const airfieldDetails = fetchedState.airfield; // Access the airfield property
            setAirfieldState({
              id: airfieldDetails.id,
              state: airfieldDetails.state, // Adjusted for naming difference
              cafe_state: airfieldDetails.cafe_state, // Adjusted for naming difference
              banner_message: airfieldDetails.banner_message, // Adjusted for naming difference
            });
          } else {
            console.error("Failed to fetch airfield details: success=false");
          }
        } catch (error) {
          console.error("Error fetching airfield state:", error);
        }
      };
      fetchAirfieldState();
    }, []);
    
    
    const handleDelete = async (bookingId) => {
      try {
        const token = localStorage.getItem('adminToken');
        console.log("In Delete");
        const resp = await deleteBooking(bookingId, token);
        console.log("Response from delete:", resp);
      } catch (error) {
        console.error('Error deleting booking:', error);
      } finally {
        setDeleteDialogOpen(false);
      }
    };
  
    const handleAirfieldSave = async () => {

      try {
        const token = localStorage.getItem('adminToken'); // Ensure the token is included
        await updateAirfield(airfieldState, token);
      } catch (error) {
        console.error('Error updating airfield:', error);
        alert('Failed to update airfield .');
      }
      handleAirfieldDialogClose();
    };

  // Logout function
  const handleLogout = () => {
    localStorage.removeItem('user');
    setUser(null);
    setIsLoggedIn(false);
    localStorage.removeItem('adminToken');
    navigate('/');
  };

  // Fetch bookings by range
  const fetchBookings = async (range) => {
    try {
      const token = localStorage.getItem('adminToken');
      const bookings = await fetchBookingsByRange(range, token); // Dynamic API call
      const sortedBookings = bookings.sort((a, b) => new Date(`${b.date}T${b.time}`) - new Date(`${a.date}T${a.time}`));
      setAllBookings(sortedBookings.slice(0, 100));
      setFilteredBookings(sortedBookings.slice(0, 100));
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };

  // Initial fetch: Default to today
  useEffect(() => {
    fetchBookings('today');
  }, []);

  // Handle time-range button clicks
  const handleTimeRangeClick = (range) => {
    fetchBookings(range);
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearch(searchTerm);
    setFilteredBookings(
      allBookings.filter((booking) =>
        ['pilot', 'registration', 'date'].some((field) =>
          booking[field]?.toLowerCase().includes(searchTerm)
        )
      )
    );
  };

  // Export filtered bookings to CSV
  const exportToCSV = () => {
    const csvContent = [
      ['In/Out', 'Date', 'Time', 'A/C Type', 'Registration', 'From / To', 'Pilot', 'Pax'],
      ...filteredBookings.map((booking) => [
        booking.booking_type,
        booking.date,
        booking.time,
        booking.aircraft_type,
        booking.registration,
        booking.location,
        booking.pilot,
        booking.pax,
      ]),
    ]
      .map((row) => row.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'bookings.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Open and close edit dialog
  const handleEdit = (booking) => {
    if (!booking.id) {
      console.error('Booking data is missing an ID:', booking);
      alert('Cannot edit this booking due to missing data.');
      return;
    }
    setSelectedBooking({ ...booking }); // Clone for editing
    setEditDialogOpen(true);
  };

  const handleEditCancel = () => {
    setEditDialogOpen(false);
    setSelectedBooking(null);
  };

  const handleEditSave = async () => {
    if (!selectedBooking || !selectedBooking.id) {
      alert('Invalid booking data. Unable to save changes.');
      return;
    }
  
    try {
      const token = localStorage.getItem('adminToken'); // Ensure the token is included
      await updateBooking(selectedBooking.id, selectedBooking, token);
      setEditDialogOpen(false);
      fetchBookings('today'); // Refresh bookings
    } catch (error) {
      console.error('Error updating booking:', error);
      alert('Failed to update booking.');
    }
  };

  // Render booking card
  const renderBookingCard = (booking) => (

    <Card variant="outlined" sx={{ marginBottom: 1, padding: 1 }}>
      <CardContent>
        <Grid2 container spacing={2}>
          <Grid2 xs={6} sm={3} sx={{ minWidth: '10px' }}>
            <Typography variant="subtitle2" color="textSecondary">In/Out</Typography>
            <Typography>{booking.booking_type}</Typography>
          </Grid2>
          <Grid2 xs={6} sm={3} sx={{ minWidth: '80px' }}>
            <Typography variant="subtitle2" color="textSecondary">Date</Typography>
            <Typography>{dayjs(booking.date).format('DD-MM-YYYY')}</Typography>
          </Grid2>
          <Grid2 xs={6} sm={3} sx={{ minWidth: '50px' }}>
            <Typography variant="subtitle2" color="textSecondary">Time</Typography>
            <Typography>{booking.time}</Typography>
          </Grid2>
          <Grid2 xs={6} sm={3} sx={{ minWidth: '100px' }}>
            <Typography variant="subtitle2" color="textSecondary">A/C Type</Typography>
            <Typography>{booking.aircraft_type}</Typography>
          </Grid2>
          <Grid2 xs={6} sm={4} sx={{ minWidth: '60px' }}>
            <Typography variant="subtitle2" color="textSecondary">Registration</Typography>
            <Typography>{booking.registration}</Typography>
          </Grid2>
          <Grid2 xs={6} sm={4} sx={{ minWidth: '130px' }}>
            <Typography variant="subtitle2" color="textSecondary">From / To</Typography>
            <Typography>{booking.location}</Typography>
          </Grid2>
          <Grid2 xs={6} sm={4} sx={{ minWidth: '130px' }}>
            <Typography variant="subtitle2" color="textSecondary">Pilot</Typography>
            <Typography>{booking.pilot}</Typography>
          </Grid2>
          <Grid2 xs={6} sm={2} sx={{ minWidth: '10px' }}>
            <Typography variant="subtitle2" color="textSecondary">POB</Typography>
            <Typography>{booking.pax}</Typography>
          </Grid2>
        </Grid2>
        <Grid2 container justifyContent="flex-end" sx={{ marginTop: 2 }}>
          <Grid2>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEdit(booking)}
            >
              Edit
            </Button>
          </Grid2>
          <Grid2>
  <Button
    variant="outlined"
    color="secondary"
    onClick={() => handleDeleteClick(booking.id)}
  >
    Delete
  </Button>
</Grid2>

<Dialog
  open={deleteDialogOpen}
  onClose={cancelDelete}
>
  <DialogTitle>Are you sure?</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Are you sure you want to delete this aircraft movement?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={cancelDelete} color="primary">
      Cancel
    </Button>
    <Button onClick={confirmDelete} color="secondary">
      Delete
    </Button>
  </DialogActions>
</Dialog>

        </Grid2>
      </CardContent>
    </Card>
    

  );

  return (
    <div className="admin-home-container">
      <div className="admin-home-header">
        <h1 className="home-page-title">Admin Dashboard</h1>
        <div className="home-buttons">
          {['Today', 'Month', 'Year', 'All Time'].map((range) => (
            <Button
              key={range}
              variant="contained"
              color="secondary"
              onClick={() => handleTimeRangeClick(range.toLowerCase())}
            >
              {range}
            </Button>
          ))}

<Button variant="contained" color="primary" onClick={() => navigate('/booking?type=In&redirect=adminHomepage')}>
  Book In
</Button>
<Button variant="contained" color="secondary" onClick={() => navigate('/booking?type=Out&redirect=adminHomepage')}>
  Book Out
</Button>

  <Button
    variant="contained"
    color="primary"
    onClick={handleAirfieldDialogOpen}
  >
    Manage Airfield
  </Button>
          <Button variant="outlined" color="secondary" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      </div>

{/* Status Container */}
<div className="status-container">
  <Typography variant="body2" className="status-item">
    <strong>Airfield Status:</strong> {airfieldState.state}
  </Typography>
  <Typography variant="body2" className="status-item">
    <strong>Cafe Status:</strong> {airfieldState.cafe_state}
  </Typography>
  <Typography variant="body2" className="status-item">
    <strong>Banner Message:</strong> {airfieldState.banner_message}
  </Typography>
</div>


      <div className="search-container">
        <TextField
          label="Search Bookings"
          variant="outlined"
          fullWidth
          value={search}
          onChange={handleSearchChange}
        />
        <Button variant="contained" color="success" onClick={exportToCSV}>
          Export to CSV
        </Button>
      </div>

      <div className="bookings-container">
        {filteredBookings.map((booking, index) => (
          <React.Fragment key={index}>{renderBookingCard(booking)}</React.Fragment>
        ))}
      </div>

      <Dialog open={editDialogOpen} onClose={handleEditCancel}>
  <DialogTitle>Edit Booking</DialogTitle>
  <DialogContent>
    {selectedBooking && (
      <>
      <FormControl fullWidth margin="normal" required>
  <InputLabel id="booking-type-label">In or Out</InputLabel>
  <Select
    labelId="booking-type-label"
    value={selectedBooking.booking_type || ''}
    onChange={(e) =>
      setSelectedBooking({ ...selectedBooking, booking_type: e.target.value })
    }
    placeholder="In/Out"
  >
    <MenuItem value="In">In</MenuItem>
    <MenuItem value="Out">Out</MenuItem>
  </Select>
</FormControl>
        <TextField
          label="Registration"
          value={selectedBooking.registration || ''}
          onChange={(e) =>
            setSelectedBooking({ ...selectedBooking, registration: e.target.value.toUpperCase() })
          }
          placeholder="Registration"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Aircraft Type"
          value={selectedBooking.aircraft_type || ''}
          onChange={(e) =>
            setSelectedBooking({ ...selectedBooking, aircraft_type: e.target.value.toUpperCase() })
          }
          placeholder="Aircraft Type"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Pilot"
          value={selectedBooking.pilot || ''}
          onChange={(e) =>
            setSelectedBooking({ ...selectedBooking, pilot: e.target.value.toUpperCase() })
          }
          placeholder="Pilot"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Passengers (incl. Pilot)"
          type="number"
          value={selectedBooking.pax || ''}
          onChange={(e) =>
            setSelectedBooking({ ...selectedBooking, pax: Number(e.target.value) })
          }
          placeholder="No. of Passengers"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="From / To"
          value={selectedBooking.location || ''}
          onChange={(e) =>
            setSelectedBooking({ ...selectedBooking, location: e.target.value.toUpperCase() })
          }
          placeholder="From / To"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Date"
          type="date"
          value={selectedBooking.date || ''}
          onChange={(e) => setSelectedBooking({ ...selectedBooking, date: e.target.value })}
          fullWidth
          margin="normal"
          required
          InputLabelProps={{
            shrink: true, // Ensures label stays visible when date is selected
          }}
        />
        <TextField
          label="Time"
          type="time"
          value={selectedBooking.time || ''}
          onChange={(e) => setSelectedBooking({ ...selectedBooking, time: e.target.value })}
          fullWidth
          margin="normal"
          required
          InputLabelProps={{
            shrink: true, // Ensures label stays visible when time is selected
          }}
        />
      </>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleEditCancel} color="secondary">
      Cancel
    </Button>
    <Button onClick={handleEditSave} color="primary">
      Save
    </Button>
  </DialogActions>
</Dialog>


  <Dialog open={airfieldDialogOpen} onClose={handleAirfieldDialogClose}>
  <DialogTitle>Manage Airfield</DialogTitle>
  <DialogContent>
    {/* Status Field */}
    <Typography>Status:</Typography>
    <Select
      fullWidth
      label="Status"
      value={airfieldState.state}
      onChange={(e) => setAirfieldState({ ...airfieldState, state: e.target.value })}
      margin="dense"
    >
      <MenuItem value="Active">Active</MenuItem>
      <MenuItem value="Restricted">Restricted</MenuItem>
      <MenuItem value="Closed">Closed</MenuItem>
    </Select>

    {/* Cafe Field */}
    <Typography>Cafe:</Typography>
    <Select
      fullWidth
      label="Cafe Status"
      value={airfieldState.cafe_state}
      onChange={(e) => setAirfieldState({ ...airfieldState, cafe_state: e.target.value })}
      margin="dense"
    >
      <MenuItem value="Open">Open</MenuItem>
      <MenuItem value="Closed">Closed</MenuItem>
    </Select>

    {/* Banner Message Field */}
    <Typography>Banner Message:</Typography>
    <TextField
      label="Banner Message"
      value={airfieldState.banner_message}
      onChange={(e) => setAirfieldState({ ...airfieldState, banner_message: e.target.value })}
      fullWidth
      margin="dense"
    />
  </DialogContent>

  <DialogActions>
    <Button onClick={handleAirfieldDialogClose} color="primary">
      Cancel
    </Button>
    <Button onClick={handleAirfieldSave} color="secondary">
      Save
    </Button>
  </DialogActions>
</Dialog>



    </div>
  );
};

export default AdminHomePage;
