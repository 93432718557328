// App.js
import React,  { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./components/HomePage";
import BookingPage from "./components/BookingPage";
import AdminHomePage from './components/AdminHomePage';
import AdminLoginDialog from './components/AdminLoginDialog';
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false); // For admin access

  return (
    <Router>
      <Routes>
        <Route
          path="/admin"
          element={
            isAdminLoggedIn ? (
              <AdminHomePage
                user={user}
                setUser={setUser}
                setIsLoggedIn={setIsLoggedIn}
              />
            ) : (
              <Navigate to="/admin-login" />
            )
          }
        />
        {/* Add route for admin login dialog */}
        <Route
          path="/admin-login"
          element={
            <AdminLoginDialog setIsAdminLoggedIn={setIsAdminLoggedIn} />
          }
        />
        <Route path="/" element={<HomePage />} />
        <Route path="/booking" element={<BookingPage />} />
      </Routes>
    </Router>
  );
};


export default App;
